.Scan {
  height: 100%;

  .MuiButton-root {
    background-color: var(--primary-color) !important;
    color: white;
  }

}

body {
  margin: 0;
}

#scan-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background: var(--darker-secondary-color);
  color: white;
  font-size: 30px;
  margin-bottom: 1em;

  #scan-header-company-logo {
    max-height: 75px;
    margin-left: 0.5em;
  }

  #settings-button {

    margin-right: 1em;

    #settings-icon {
      color: var(--primary-color);
    }
  }

}

#scanInput {
  margin-top: 3em;

  input {
    width: 20em;
  }

  Button {
    margin-left: 1em;
  }
}