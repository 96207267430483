.SettingsMaintenancePeriod{
 
    #maintenance-periode-type{
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 15%;
    }
    #maintenance-periode-value{
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 10%;
    }
}