.ScanInfo {

    #info-text {
        width: 65%;
        text-align: center;
        margin-bottom: 5em;
        margin-top: 1em;
    }

    #scan-logo {
        width: 100%;
        max-width: 400px;
        height: auto;
    }
}