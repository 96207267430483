:root {
    /*Hintergrundfarbe der Seite*/
    --main-background-color: white;

    /*Farbe des Headers*/
    --header-background-color: #5f5f5f;

    /*Farbe eines ungeraden Tabellenreihen Index*/
    --row-odd-color: white;

    /*Farbe eines geraden Tabellenreihen Index*/
    --row-even-color: #f2f2f275;

    /*Schriftgröße des Produkttitels*/
    --font-size-heading: 28px;

    /*Schriftgröße der einzelnen Abschnitte*/
    --font-size-section-heading: 20px;

    /*Schriftart der Seite*/
    --text-font: Arial, Helvetica, sans-serif;
}

.fa-check-circle {
    color: #b1cc38;
}

#impressum {
    padding-left: 25%;
    padding-right: 25%;
    text-align: center;
}

#info {
    padding-left: 25%;
    padding-right: 25%;
    text-align: center;
}


/*
Überschrift
Beinhaltet Titel des Produkts welches angezeigt wird
*/
.heading {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: var(--font-size-heading);
    font-weight: bold;
}

/*
Eigentlicher Inhalt
Zeigt alle Attribute des Produkts an
*/
.content {
    padding: 10px;
    background-color: var(--main-background-color);
    width: 100%;
}

.centering {
    display: flex;
    justify-content: center;
    align-items: center;
}

.poweredby {
    position: relative;
    /*neu*/
    bottom: 0;
    /*neu*/
    display: grid;
    text-align: center;
    margin-top: 2Svh;
}

.poweredby img {
    max-height: 70px;
    width: auto;
}

.margin-hyperlink {
    margin-right: 25px;
    margin-left: 10px;
}

/*
Überschirft eines Abschnitts zum Aufklappen
(section-heading)
*/
summary {
    font-size: var(--font-size-section-heading);
    font-weight: bold;
}

#product-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-heading);
    font-weight: bold;
}

/*Anzeige des Produkt Icons*/
#product-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#product-icon-container img {
    max-width: 80%;
    max-height: 200px;
}


td {
    border-top: 1px solid #dee2e694 !important;
}

table {
    table-layout: fixed;
    border-collapse: collapse;
    word-wrap: break-word;
}

tr:nth-child(even) {
    background-color: var(--row-even-color);
}

tr:nth-child(odd) {
    background-color: var(--row-odd-color);
}

@media only screen and (min-width: 800px) {
    .content {
        padding-left: 15%;
        padding-right: 15%;
    }
}

@media only screen and (min-width: 1400px) {
    .content {
        padding-left: 20%;
        padding-right: 20%;
    }
}