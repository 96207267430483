.Login {
    width: 100%;

    #login-header {
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 0.2em solid #4a4a4a;

        #logo {
            height: 60px;
            width: auto;
        }
    }

    #login-form {
        height: calc(100vh - 90px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

#login-card {

    .Mui-focused {
        color: var(--primary-color) !important;
    }

    .MuiCardContent-root {
        padding-left: 2em;
        padding-right: 2em;
        padding-bottom: 2em;

    }

    .MuiCardHeader-root {
        background-color: var(--primary-color);
        text-align: center;
        color: white;
    }


    .LoginForm {
        width: 25em;

        .MuiInputBase-input {
            color: #4a4a4a;
        }

        .MuiInput-underline:hover:before {
            border-bottom-color: var(--primary-color) !important;
        }

        .MuiInput-underline:after {
            border-bottom-color: var(--primary-color) !important;
        }

        #input-username-wrapper {
            margin-bottom: 1em;
        }

        #input-password-wrapper {
            margin-bottom: 2em;
        }

        #signin-button-wrapper {
            margin-top: 1em;

            .MuiButton-root {
                background-color: var(--primary-color);
                color: white;
            }
        }

        .MuiFormControl-root {
            width: 100% !important;
        }
    }

}