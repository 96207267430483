.Settings {

    .modal-dialog{
        max-width: 65%;
    }

    #cancel-button {
        background-color: red;
        color: white;
    }

    #save-button {
        background-color: green;
        color: white;
    }
}